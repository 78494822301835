import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ArticulosParams,
  requestArticulos,
} from "../../store/articulos/articulos.actions";
import { Familia, Subfamilia } from "../../classes/familia";
import Articulo from "../../classes/articulo";
import Button from "../../core/Button/Button";
import Cliente from "../../classes/cliente";
import Error from "./../../components/Error/Error";
import Spinner from "./../../core/Spinner/Spinner";
import ProductRow, { IProductRow } from "../ProductRow/ProductRow";
import qs from "qs";

import "./ProductList.scss";
import Logo from "../Logo/Logo";
import Input from "../../core/Input/Input";

const ARTICULOS_PER_PAGE = 50;

const ProductList: React.FunctionComponent<any> = ({ location }) => {
  const { familia, subfamilia, filter } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { articulos, error, fetching } = useSelector(
    (state: any) => state.articulosStore
  );

  const cliente: Cliente = useSelector((state: any) => state.authStore.user);
  const familias: Familia[] = useSelector(
    (state: any) => state.familiasStore.familias
  );
  const listapre: number = cliente && cliente.LISTAPRE;

  const [paginationReady, setPaginationReady] = useState<boolean>(false);
  const [paginationButtons, setPaginationButtons] = useState<any[]>([]);
  const [visibleArticulos, setVisibleArticulos] = useState<Articulo[]>([]);
  const savedDiscount = localStorage.getItem("rthDiscount");
  const savedSugerido = localStorage.getItem("rthSugerido");
  const [discount, setDiscount] = useState<number | undefined>(
    savedDiscount ? parseFloat(savedDiscount) : undefined
  );
  const [sugerido, setSugerido] = useState<number | undefined>(
    savedSugerido ? parseFloat(savedSugerido) : undefined
  );

  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    if (familia || subfamilia || filter) {
      setVisibleArticulos([]);
      setPaginationButtons([]);
      setPaginationReady(false);

      let params: ArticulosParams = {};

      if (familia) {
        params.familia = Number(familia);
        if (Number(subfamilia)) {
          params.subfamilia = Number(subfamilia);
        }
      }

      if (filter) {
        params.filter = filter as string;
      }

      if (familia || filter) dispatch(requestArticulos(params));
    }
  }, [dispatch, filter, familia, subfamilia]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [fetchData]);

  const setArticulosByPage = (page: number) => {
    let firstArticulo: number = 0;

    for (let i = page; i > 1; i--) firstArticulo += ARTICULOS_PER_PAGE;

    setVisibleArticulos(
      articulos.slice(firstArticulo, firstArticulo + ARTICULOS_PER_PAGE)
    );
    setPaginationReady(true);
    setPaginator(page);
    window.scrollTo(0, 0);
  };

  const setPaginator = (activePage: number) => {
    const totalPages: number = Math.ceil(articulos.length / ARTICULOS_PER_PAGE),
      paginator: any[] = [];

    for (let i = 1; i <= totalPages; i++) {
      const acceptedRange: boolean = Math.abs(activePage - i) < 5;

      if (acceptedRange) {
        paginator.push(
          <Button
            click={() => setArticulosByPage(i)}
            className={activePage === i ? "ActivePage" : "PageButton"}
            key={`p${i}`}
          >
            {i}
          </Button>
        );
      }
    }
    setPaginationButtons(paginator);
  };

  const onDiscountChange = (evt: any) => {
    const val = evt.target.value;
    setDiscount(val);
    localStorage.setItem("rthDiscount", val);
  };
  const onSuggestionChange = (evt: any) => {
    const val = evt.target.value;
    setSugerido(val);
    localStorage.setItem("rthSugerido", val);
  };

  const getSugerido = (articulo: Articulo): number => {
    const price = getProductPrice(articulo);
    const priceWithDiscount: number = price - (price * (discount || 0)) / 100;
    return +(
      priceWithDiscount +
      (priceWithDiscount * (sugerido || 0)) / 100
    ).toFixed(2);
  };

  const getProductPrice = (articulo: Articulo): number => {
    switch (listapre) {
      case 1:
        return articulo.PVENTA_1;
      case 2:
        return articulo.PVENTA_2;
      case 3:
        return articulo.PVENTA_3;
      case 4:
        return articulo.PVENTA_4;
      default:
        return 0;
    }
  };

  if (error) {
    return (
      <Error
        msg="Hubo un error inesperado. Por favor intente nuevamente."
        retry={fetchData}
      />
    );
  }

  if (fetching) {
    return <Spinner>Buscando productos...</Spinner>;
  }

  if (!Number(familia) && !Number(subfamilia) && !filter) {
    return (
      <div className="ProductGetStarted">
        <p>Busque productos o seleccione una familia del recuadro.</p>
        <div className="separator"></div>
        <Logo />
      </div>
    );
  }

  if (!articulos.length) {
    return <p>No se encontraron artículos para los filtros establecidos.</p>;
  }

  if (!paginationReady) {
    setArticulosByPage(1);
  }

  return (
    <div className="ProductList">
      <div className="Porcentages">
        <div className=" PercentajeContainer">
          <div className="title">Porcentaje de descuento (%)</div>
          <div className="">
            <Input
              className="percentageInput"
              value={discount}
              type="number"
              id="discount"
              change={onDiscountChange}
            />
          </div>
        </div>
        <div className="PercentajeContainer">
          <div className="title">Porcentaje de venta (%)</div>
          <div className="">
            <Input
              value={sugerido}
              type="number"
              id="suggested"
              change={onSuggestionChange}
            />
          </div>
        </div>
      </div>
      <p className="text-center">
        <b>Precios finales en pesos. Incluye IVA.</b>
      </p>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>CÓDIGO</th>
            <th>DESCRIPCIÓN</th>
            <th>CATEGORÍAS</th>
            <th>PRECIO LISTA</th>
            <th>PRECIO SUGERIDO</th>
            <th>CANTIDAD</th>
            <th>STOCK</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {visibleArticulos.map((a: Articulo, index: number) => {
            const familia: Familia | undefined = familias.find(
              (f: Familia) => f.ID_FLIA === a.ID_FLIA
            );
            const subfamilia =
              familia &&
              familia.SUBFAMILIAS.find(
                (s: Subfamilia) => s.ID_SUBF === a.ID_SUBF
              );

            const product: IProductRow = {
              codart: a.CODART,
              desart: a.DESART,
              familia: familia && familia.NFAMILIA,
              subfamilia: subfamilia && subfamilia.NSUBF,
              price: getProductPrice(a),
              imgUrl: a.FILE1,
              sugerido: getSugerido(a),
              nota: a.NOTA,
              existencia: a.EXISTENCIA,
            };

            return <ProductRow key={index} {...product} />;
          })}
        </tbody>
      </table>
      <div className="pagination">{paginationButtons}</div>
    </div>
  );
};

export default ProductList;
