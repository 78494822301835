import React, { useEffect, useState } from "react";
import QtySelector from "../QtySelector/QtySelector";
import Currency from "../../core/Currency/Currency";
import "./ProductRow.scss";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CircleIcon from "@mui/icons-material/Circle";
const ReactImageZoom = require("react-image-zoom");

export interface IProductRow {
  codart: string;
  desart: string;
  familia?: string;
  subfamilia?: string;
  price: number;
  imgUrl?: string;
  sugerido?: number;
  nota?: string;
  existencia: number;
}

const ProductRow: React.FC<IProductRow> = (articulo: IProductRow) => {
  const [isControlPressed, setIsControlPressed] = useState(false);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  const {
    codart,
    desart,
    familia,
    subfamilia,
    price,
    imgUrl,
    sugerido,
    nota,
    existencia,
  } = articulo;

  const stockOptions = {
    low: {
      color: "red",
      text: "STOCK MINIMO O NULO. CONSULTAR",
    },
    medium: {
      color: "yellow",
      text: "STOCK BAJO. CONSULTAR",
    },
    high: { color: "green", text: "EN STOCK" },
  };

  const stockLevel =
    !existencia || existencia === 1
      ? "low"
      : existencia >= 2 && existencia <= 5
      ? "medium"
      : "high";

  const stockData = stockOptions[stockLevel];

  const imgProps = {
    width: 100,
    height: 80,
    zoomWidth: 500,
    scale: 5,
    img: imgUrl
      ? `http://rthrepuestos.ar/img/${imgUrl}`
      : "./assets/no-disponible.jpg",
    zoomPosition: "right",
    offset: {
      horizontal: 10,
      vertical: -100,
    },
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        setIsControlPressed(true);
      }

      if (event.shiftKey) {
        setIsShiftPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!event.ctrlKey) {
        setIsControlPressed(false);
      }

      if (!event.shiftKey) {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <tr className="ProductRow">
      <td>
        <ReactImageZoom {...imgProps} />
      </td>
      <td className="Desart">
        <p>
          <span className="Codart">{codart.toUpperCase()}</span>
        </p>
      </td>
      <td className="Desart">
        <p>{desart.toUpperCase()}</p>
      </td>
      <td className="Categorias">
        <p>
          {familia || "-"} <br /> {subfamilia || "-"}
        </p>
      </td>
      <td className="Price">
        <p>
          <Currency value={price} />
        </p>
      </td>
      <td className="Price">
        <p>{sugerido ? <Currency value={sugerido} /> : null}</p>
      </td>
      <td className="QtyCell">
        <QtySelector
          desart={desart}
          codart={codart}
          price={price}
          isCheckout={false}
        />
      </td>
      <td>
        <Tooltip title={stockData.text}>
          <IconButton>
            <CircleIcon sx={{ color: stockData.color }} />
          </IconButton>
        </Tooltip>
        {isControlPressed && isShiftPressed && <b>Stock: {existencia || 0}</b>}
      </td>
      <td>
        {nota && (
          <Tooltip title={nota}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
      </td>
    </tr>
  );
};

export default ProductRow;
